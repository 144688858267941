.applied-filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    
   
    .filter-chip {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      border-radius: 3px;
      background-color: var(--color-grey-light-1);
      padding: 8px 12px;
      color: var(--color-grey-dark-3);
      svg {
        margin-left: 5px;
        cursor: pointer;
        font-size: 1.4rem;
        color: var(--color-grey-dark-3);
      }
    }
    .clear-filters-btn {
      border: none;
      background-color: var(--dark-gold);
      color: white;
      padding: 0.6rem 0.9rem;
      @media only screen and (max-width: $bp-smallest) {
      display: none;
      }
      
    }
  }