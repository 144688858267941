a.link{
  text-decoration: none;
}
.product-card {
  //max-width: 300px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  overflow: hidden;
  cursor: pointer;
  background-color: white;

  &__img {
    width: 100%;
    grid-row: 1/2;
    grid-column: 1/-1;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__icons {
    background-color: transparent;
    grid-row: 1/2;
    grid-column: 2/3;
    width: 80px;
    height: 80px;
    z-index: 10;
    justify-self: end;
    margin: 1rem 0.5rem;
    display: flex;
    //flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 0.6rem;

    &-item-1 {
      cursor: pointer;
      height: 3rem;
      width: 3rem;
      padding: 0.5rem;
      border-radius: 50%;
      position: relative;
      background-color: black; // Use a consistent background color
      border: 2px solid black; // Set border to match fill color
      color: white; // Icon color
  
      &:hover {
        background-color: white;
        color: black; // Change icon color on hover
        border: 2px solid black; // Maintain border color on hover
      }
    }
    &-item-2 {
      cursor: pointer;
      height: 3rem;
      width: 3rem;
      padding: 0.5rem;
      border-radius: 50%;      
      position: relative;
      background-color: var(--color-primary-light); // Use a consistent background color
      border: 2px solid var(--color-primary-light); // Set border to match fill color
      color: white; // Icon color
  
      &:hover {
        background-color: var(--color-primary-dark); // Change background on hover
        color: white; // Maintain icon color
        border: 2px solid var(--color-primary-dark); // Change border to match hover color
      }      

     
    }
  }

  &__detail {
    background-color: whitesmoke;
    text-decoration: none;
    grid-column: 1/-1;
    padding: 0.8rem 2rem;
    @media only screen and (max-width: $bp-smallest) {
      padding: 0.2rem 0.2rem;
    }

    div {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-name {
      text-decoration: none;
      color: #333333;
      font-size: 15px;
      align-self: flex-start;
      font-weight: 400;
      @media only screen and (max-width: $bp-smallest) {
        font-size: 14px;
      }
    }
    &-price {
      text-decoration: none;
      color: var(--color-primary-dark);
      font-size: 1.2rem;
      align-self: flex-start;

      font-weight: bold;
      @media only screen and (max-width: $bp-smallest) {
        font-size: 1.4rem;
      }
    }
  }
}
