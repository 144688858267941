.loading-screen{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: var(--color-pink-dark);
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    h1{
        font-size: 2rem;
        color:white;
        margin-top: 1rem;
    }
    @keyframes fadeIn {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
        
    }


}