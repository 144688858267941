:root {
    --color-primary: #eb2f64;
    --color-primary-light: #FF3366;
    --color-primary-dark: #BA265D;

    --color-grey-light-1: #ebdede;
    --color-grey-light-2: #f4f2f2;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;
    --color-white:#fff;
    --dark-gold: #B8860B;
    --light-gold: #E4B400;
    --green-1:#198450;
    --green-2:#27a567;
    --green-3:#2eb774;
    --green-4:#38cb82;
    --green-5:#41dc8e;
    --green-6:#64e3a1;
    
    --color-grey-dark-1: #161616;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: rgb(95, 89, 89);
    --color-violet:#8745B5;
    --color-violet-light:#A23CE8;
    --color-violet-dark:#571E7D;
    --color-blue:#1974CAD4;
    --color-blue-light:#1974CAD4;
    --color-blue-dark:#1974CAD4;
    --color-sky-blue:rgb(119, 209, 245);
    --color-blue-darkest: rgba(2, 2, 34, 0.99);
    --color-black:rgb(6, 1, 15);
    --color-pink-dark:rgba(171, 8, 78, 0.99);
    --color-pink-light: rgba(255, 10, 115, 0.99);
    --color-pink-medium-dark: rgba(181, 12, 86, 0.99);
    --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
    --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);

    --line: 1px solid var(--color-grey-light-2);
}
$bp-largest: 75em;     // 1200px
$bp-large: 68.75em;    // 1100px
$bp-medium: 56.25em;   // 900px
$bp-small: 30.5em;     
$bp-smallest: 32.25em;
