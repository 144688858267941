
  .cart-wrapper {
    margin-top: 2rem;
    grid-column: full-start/full-end;
    padding-left: 4rem;
    padding-right: 4rem;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 2rem;
    @media only screen and (max-width: $bp-smallest) {
      grid-column: full-start/full-end;
      padding-left: 1rem;
      padding-right: 1rem;
      grid-template-columns: 1fr;
    }
    .left {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .cart-item {
        background-color: white;
        display: grid;
        grid-template-columns: repeat(8, 1fr); /* 5 columns for the content */
        grid-column-gap: 1rem; /* Spacing between columns */
        align-items: center; /* Center content vertically within each grid cell */
        padding: 1rem;
        position: relative;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
       transition: opacity 0.4s ease,transform 0.4s ease;
        @media only screen and (max-width: $bp-smallest) {
        grid-column-gap:0;
          grid-template-columns: repeat(4, 1fr);
          padding-top: 3rem;
        }
        &__img-container{
          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 4px;
           
          }
          @media only screen and (max-width: $bp-smallest) {
            grid-row: 1 / span 2;
          }

        }
       
        &__heading {
          font-size: 1rem; /* Increased font size for better readability */
          font-weight: 500; /* Medium weight for the heading */
          font-family: inherit;
          color: inherit;
        }
        &__color {
          display: flex;
          align-items: center;
          span {
            font-size: 1rem;
            margin-right: 0.5rem;
            color: inherit;
          }
          div {
            width: 30px;
            height: 20px;
            border-radius: 4px;
          }
        }
        &__size,
        &__qty,
        &__price {
          font-size: 1rem;
          font-family: inherit;
          color: inherit;
        }
        &__size {
          text-align: center;
          @media only screen and (max-width: $bp-smallest) {
            text-align: start;
          }
        }
        &__qty {
          text-align: center;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          span:first-child{
            @media only screen and (max-width: $bp-smallest) {
             display: none;
            }
          }
         
          @media only screen and (max-width: $bp-smallest) {
            grid-column: 2/ span 1;
          
          }
          &-btn {
            border: none;
            background-color: var(--color-grey-light-1);
            color: var(--color-grey-dark-3);
            padding: 0.3rem 0.5rem;
            border-radius: 3px;
          }
        }

        &__price {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: flex-start; /* Align price content to the left */
          gap: 0.7rem;

          span {
            font-size: 1.1rem;
            color: #888; /* Lighter color for "Price" label */
          }

          h6 {
            font-size: 1.1rem;
            color: var(--color-grey-dark-3);
            margin: 0; /* Remove default margin from h6 */
          }
        }
        &__action {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          @media only screen and (max-width: $bp-smallest) {
            position: absolute;
            top: 5px;
            right: 5px;
          }

          button {
            position: relative;
            background-color: transparent;
            outline: none;
            border: none;
            cursor: pointer; // Add a pointer cursor for better UX
            padding: 0; // Remove default padding

            svg {
              width: 1.5rem;
              height: 1.5rem;
              @media only screen and (max-width: $bp-smallest) {
                width: 1.7rem;
                height: 1.7rem;
              }
            }

            &:first-child svg {
              fill: var(--green-3);
            }

            &:last-child svg {
              fill: rgb(221, 96, 96);
              margin-left: 0.5rem; // Add margin-left to the last button
            }
          }
          button::after {
            content: attr(
              data-tooltip
            ); /* Tooltip text comes from the data-tooltip attribute */
            position: absolute;
            bottom: 120%; /* Position tooltip above the button */
            left: 50%;
            transform: translateX(-50%);
            background-color: #333;
            color: #fff;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 12px;
            white-space: nowrap;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease, visibility 0.3s ease;
            z-index: 10; /* Ensure the tooltip is above other elements */
          }
          button:hover::after {
            opacity: 1;
            visibility: visible;
          }
          button:hover::after {
            transition-delay: 0.1s;
          }
        }
      }
      .cart-item.removing {
        opacity: 0; /* Fade out */
        transform: translateX(100%); /* Slide out */
      }
    }
    .right {
      padding: 3rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      border-radius: 8px;
      background-color: white;
      h2 {
        text-align: center;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-family: inherit;
      }
      .coupon-code-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: $bp-smallest) {
          justify-content: flex-start;
          gap:0.5rem;
        }
        
        input{
          padding:0.5rem 0.4rem;
          border:none;
          background-color: var(--color-grey-light-1);
          border-radius: 4px;
        }
        button{
          background-color: var(--light-gold);
          color:white;
          border:none;
          padding:0.5rem 0.3rem;
          font-size: 0.7rem;
          font-weight: bold;
          font-family: inherit;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          border-radius: 4px;
          &:last-child{
            background-color: var(--green-3);
          }
        }
      }
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 1.2rem;
          font-family: inherit;
        }
      }
      &_coupon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          display: flex;
          align-items: center;
          gap: 1rem;

          .right_coupon_item {
            border: none;
            background-color: var(--light-gold);
            color: white;
            font-size: 0.7rem;
            font-weight: bold;
            border-radius: 4px;
            padding: 0.5rem 1rem;
            border: 1px dashed var(--color-grey-dark-3);
          }
        }
        &_btn {
          background-color: var(--green-3);
          border: none;
          color: white;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          text-transform: uppercase;
        }
        &_btn-checkout {
          color: white;
          border: none;
          background-color: var(--color-pink-dark);
          padding: 1rem 2rem;
          border-radius: 4px;
          text-transform: uppercase;
          &:hover {
            background-color: var(--dark-gold);
          }
        }
      }
    }
  }

