.empty-message {
  grid-column: 1/span 2;
   
    text-align: center;
    max-width: 100%;
    margin: 1rem auto;
    font-family: inherit;
  
    h4 {
      margin: 0;
      font-weight: 400;
      font-size: 1rem;
      
      font-family: inherit;
      color: var(--color-grey-dark-3);
    }
  
    &.no-data {
      background-color: #d4edda;
      border: 1px solid #c3e6cb;
      color: #155724;
    }
  
    &.loading {
      background-color: #fff3cd;
      border: 1px solid #ffeeba;
      color: #856404;
    }
  }
  