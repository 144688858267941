.carousel-container {
  margin-top: 1rem;
  
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;

  .heading {
    grid-column: 1 / -1; /* Ensure heading takes full width */
  }

  .carousel {
    grid-column: 1 / -1; /* Ensure carousel takes full width */
    padding: 0.5rem;

    width: 100%; /* Ensure it uses the full width of the grid */
    overflow: hidden; /* Keep overflow hidden */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;

    .carousel-button {
      background-color: none;
      color: var(--color-grey-dark-4);
      border: none;
      padding: 0.5rem 1rem;
      cursor: pointer;

      position: absolute; /* Position buttons absolutely */
      top: 50%; /* Center vertically */
      transform: translateY(-50%); /* Adjust for button height */
      z-index: 1; /* Ensure buttons are above images */
    }
    .carousel-button .icon {
      width: 2rem;
      height: 2rem;
    }
    .carousel-button.left {
      left: 10px; /* Position left button */
    }

    .carousel-button.right {
      right: 10px; /* Position right button */
    }
    .carousel-images {
      padding: 1rem;
      //background-color: pink;
      display: grid; /* Use grid layout */
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 2rem;
      width: 100%; /* Make the images container take full width */
      flex: 1; /* Allow this div to take available space */
      transition: transform 0.5s ease;
       transform: translateX(0); /* Initial position */
      @media (max-width:404px){
        grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media (max-width:900px){
        grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
      }
      @media (min-width: 900px) and (max-width: 1200px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      
    } 
    @media (min-width: 1200px) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
    
    .dots {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      position: absolute; /* Positioning dots */
      bottom: 0; /* Position at the bottom */
      left: 50%; /* Center horizontally */
      transform: translateX(-50%); /* Adjust for centering */
      @media only screen and (max-width: $bp-medium) {
       margin-top: 3rem;
       bottom:0;

       }
    }

    .dot {
      height: 15px;
      width: 15px;
      margin: 0 5px;
      background-color:white;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
      transition: background-color 0.3s;
      @media only screen and (max-width: $bp-medium) {
        height: 10px;
        width: 10px;
        
        }
    }

    .dot.active {
      background-color: var(--light-gold); /* Active dot color */
    }
  }
}

