.detail-wrapper {
  margin-top: 2rem;
  grid-column: center-start/center-end;
  padding-left: 4rem;
  padding-right: 4rem;
  font-family: "lato", sans-serif;
  display: flex;
  gap: 2rem;

  @media only screen and (max-width: $bp-smallest) {
    grid-column: full-start/full-end;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    gap: 1.5rem;
  }

  .productdisplay-left {
    display: flex;
    gap: 10px;
    @media only screen and (max-width: $bp-smallest) {
      flex-direction: column-reverse;
    }

    .productdisplay-img-list {
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: $bp-smallest) {
        flex-direction: row;
      }

      gap: 10px;
      img {
        height: 100px;
        border-radius: 8px;
        width: 100%;
        object-fit: cover;
      }
    }
    .productdisplay-main-img {
      width: 100%; // Ensure it is responsive
      height: auto; // Maintain aspect ratio
      max-width: 360px; // Max width to prevent the image from being too big
      margin-bottom: 1rem;
    }
  }
  .productdisplay-right {
    flex: 1;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $bp-smallest) {
      margin-top: 2rem;
      gap: 0.5rem;
      padding-left: 0.5rem;
    }

    .product-category {
      font-size: 1.3rem;
      font-weight: 300;
      color: var(--color-grey-dark-3);
      font-family: inherit;
      margin-bottom: 0.6rem;
    }
    .product-name {
      text-transform: uppercase;
      font-size: 1.3rem;
      font-family: inherit;
      letter-spacing: 0.1rem;
      font-weight: 400;
      margin-bottom: 0.6rem;
    }
    .product-price {
      font-size: 1.3rem;
      font-weight: bold;
      letter-spacing: 0.1rem;
      color: var(--color-grey-dark-3);
      margin-top: 1rem;
    }
    .product-color {
      color: var(--color-blue-darkest);
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 0.1rem;
    }
    .color-name {
      margin-top: 1rem;
    }
    .buttons-wrapper {
      display: flex;

      gap: 0.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-right: 3rem;
      button {
        width: 1.4rem;
        height: 1.4rem;
        border: none;
        border-radius: 50%;
      }
    }
    .product-size {
      color: var(--color-blue-darkest);
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 0.1rem;
    }
    .size-name {
      
      margin-top: 1rem;
    }
    .dropdown {
      width: 200px;
      position: relative;
      font-family: "lato", sans-serif;
      user-select: none;
      z-index: 400;
    }
    .dropdown .dropdown-btn {
      padding: 8px 15px;
      background: #fff;
      box-shadow: 3px 3px 4px 4px rgba(0, 0, 0, 0.06);
      font-weight: 500;
      font-size: 1.3rem;
      color: var(--color-grey-dark-3);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
    .dropdown .dropdown-content {
      position: absolute;
      top: 110%;
      padding: 8px 10px;
      background: #fff;
      box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
      font-weight: 400;
      font-size: 1.2rem;
      color: var(--color-grey-dark-2);
      width: 100%;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 8px 10px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    .product-details {
      color: var(--color-blue-darkest);
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 0.1rem;

      text-transform: uppercase;
    }
    .detail-name {
     
      margin-top: 1rem;
    }
    .details{
      margin-top: 0.5rem;
      font-family: inherit;
      color:var(--color-grey-dark-3);
      font-size: 1rem;
      @media only screen and (max-width: $bp-smallest) {
        text-align: center;
       }

    }
    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .submit-btn {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: none;
      outline: none;
      padding: 0.7rem 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-pink-dark);
      color: white;
      border-radius: 3px;
      transition: background-color 0.3s ease;
      &:hover{
        background-color: var(--light-gold);

      }
      @media only screen and (max-width: $bp-smallest) {
       width:100%;
       padding: 0.9rem 1.4rem;
      }

      span {
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.1rem;
        font-family: inherit;
        text-transform: capitalize;
        @media only screen and (max-width: $bp-smallest) {
         font-size: 1.2rem;
        
         }
      }
    }
    .accord-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
      font-family: inherit;

      .accord-item {
        display: flex;
        flex-direction: column;

        cursor: pointer;
        .accord-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 0.2px solid #cac2c2;
          padding: 0.8rem 0.5rem;
          border-radius: 5px;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          h4 {
            padding: 0.5rem 0;
          }

          &:hover {
            background-color: var(--color-grey-light-2);
          }

          .product-color {
            flex: 1;
          }
          .accord-arrow {
            transition: all 0.2s;
            font-size: 2rem;
            color: rgb(95, 89, 89);
          }
        }
        .accord-details {
          height: 0;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          gap:0.5rem;
          padding-top: 0.9rem;
          padding-left: 0.5rem;
          p{
           
            font-family: inherit;
            font-weight: 400;
           

          }
          p:first-child{
            font-size: 1rem;
           letter-spacing: 0.1rem;
            color:var(--color-grey-dark-3);
          }
          p:last-child{
            font-size: 1.2rem;
            color: var(--color-blue-darkest);
            text-transform: capitalize;

          }
        
        }
      }
      .open {
        border-color: #087f5b;
      }
      .open .accord-details {
        height: auto;
      }
      .open .accord-arrow {
        transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }
    }
  }
}
.related-products {
  grid-column: center-start/center-end;

  @media only screen and (max-width: $bp-smallest) {
    grid-column: full-start/full-end;
    padding-left: 1rem;
    padding-right: 1rem;
  }

 
}
.reviews-container {
  margin-top: 2rem;
  grid-column: center-start/center-end;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $bp-smallest) {
    grid-column: full-start/full-end;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .review-form {
    margin-bottom: 1rem;
    display: flex;

    flex-direction: column;
    gap: 0.5rem;
    textarea {
      width: 100%;
      padding: 0.5rem 1.3rem;
      font-size: 1.2rem;
      font-family: inherit;
      border: none;
      background-color: white;
      border-radius: 4px;
      margin-bottom: 0.6rem;
      box-sizing: border-box;
    }
    .rating-starbtn-container {
      display: flex;
      align-items: center;
      gap: 2rem;
      padding: 0 1rem;
      .rating-star {
        display: flex;
        align-items: center;
        gap: 0.3rem;

        button {
          background-color: none;
          border: none;

          svg {
            fill: var(--light-gold);
            height: 1.8rem;
            width: 1.8rem;
          }
        }
        button.half-active svg {
          fill: linear-gradient(to right, var(--light-gold) 50%, white 50%);
        }
      }
      .btn-submit {
        background-color: var(--color-pink-dark);
        color: white;
        border: none;
        border-radius: 4px;
        padding: 0.4rem 0.6rem;
        &:hover {
          background-color: var(--light-gold);
        }
      }
    }
  }
  .reviews-summary {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 1rem;

    .rating-review {
      align-self: flex-start;
      margin-right: 0.5rem;
    }
    .ratings-number {
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--color-grey-dark-3);
      margin-right: 1rem;
    }
  }
  .reviews-wrapper {
    .single-review {
      display: flex;
      flex-direction: column;
      padding: 1rem 0.4rem;
      border-bottom: 2px solid var(--color-grey-light-2);
      div {
        display: flex;
        align-items: center;
        .review-user {
          font-size: 1rem;
          font-weight: 400;
          margin-right: 0.5rem;
          color: var(--color-pink-dark);
        }
        .ratreview {
          align-self: flex-end;
        }
        .review-date {
          font-size: 1rem;
          font-weight: 400;
        }

        .review-date {
          margin-left: auto;
        }
      }
      .review-details {
        text-transform: capitalize;
        font-size: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-weight: 400;
      }
    }
  }
}
