
    .favourites-products {
      margin-top: 2rem;
      grid-column: full-start/full-end;
      padding-left: 4rem;
      padding-right: 4rem;
     
      @media only screen and (max-width: $bp-smallest) {
        grid-column: full-start/full-end;
        padding-left: 1rem;
        padding-right: 1rem;
        
        }
     
    
      }



