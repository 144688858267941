*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  height: 100%;
  font-size: 70%;
  font-weight: 300;
  color: var(--color-grey-dark-3);
  font-family: "Lato", sans-serif;
  @media only screen and (max-width: $bp-medium) {
    font-size: 55%;
  }
}

body {
  height: 100%;
  box-sizing: border-box;
}

.wrapper {
  background-color: rgb(245, 240, 240);
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  position: relative;
}

.chatbtn-container {
  position: fixed;
  bottom: 3rem;
  right: 5rem;
  z-index: 5000;
  @media only screen and (max-width: $bp-smallest) {
    bottom:5rem;
    right:2rem;
  }
}

.chatbtn {
  background: none;
  border: none;
  border-radius: 50%;
  background-color: var(--color-pink-dark);
  color: white;
  padding: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: var(--color-pink-light);
  }
  svg {
    width: 2rem;
    height: 2rem;
    fill: white;
  }
}

.chat-box {
  position: fixed;
  bottom: 4rem;
  right: 5rem;
  width: 300px;
  max-width: 100%;
  height: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  @media only screen and (max-width: $bp-medium) {
    width:90%;
    height: 70%;
    bottom:5rem;
    right:5%;
  }
}

.chat-box.open {
  transform: translateY(0);
  opacity: 1;
}

.chat-box.minimized {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  bottom: 3rem;
  right: 5rem;
  transition: height 0.3s, width 0.3s, border-radius 0.3s;
  @media only screen and (max-width: $bp-medium) {
    bottom:5rem;
    right:5%;
  }
}

.chat-box-header {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px;
  background-color: var(--color-pink-dark);
  color: white;
  border-radius: 10px 10px 0 0;

}

.minimize-btn,
.close-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  svg{
    width: 1.2rem;
    height: 1.2rem;
  }
}

.minimize-btn:hover,
.close-btn:hover {
  opacity: 0.8;
}

.chat-box-content {
  padding: 20px;
  overflow-y: auto;
  max-height: 300px;
}

.chat-box-content p {
  margin: 0;
}
.messages{
  display: flex;
  flex-direction: column;
  
}
.user-message{
  background-color: var(--color-grey-light-1);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  align-self: flex-start;
  max-width: 70%;
  color:var(--color-grey-dark-3);
}
.bot-message {
  background-color:var(--color-sky-blue);
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  align-self: flex-end;
  max-width: 70%;
}

.chat-input {
  display: flex;
  align-items: center;
}

.chat-input input {
  width: 80%;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.chat-input button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #45a049;
}
.slider {
  width: 100%;
  height: 10px;
  background-color: #ddd;
}

.slider-track {
  background-color: #007bff;
  height: 100%;
}
.chat-box ul {
  list-style: none;
  padding: 0;
}

.chat-box ul li {
  padding: 0.5rem;
  cursor: pointer;
}

.chat-box ul li:hover {
  background-color: #f0f0f0;
}

.slider-thumb {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
}









.announcement {
  background-color: var(--color-blue-darkest);
  color: var(--color-grey-light-3);
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--color-grey-light-3);
  .text {
    margin: 10px 0;
  }
}
.page-container {
  flex: 1;
  margin-top: 2rem;
  display: grid;
  font-family: "Lato", sans-serif;

  grid-template-columns:
    [full-start] minmax(13rem, 1fr) [center-start] repeat(
      8,
      [col-start] minmax(min-content, 14rem) [col-end]
    )
    [center-end] minmax(13rem, 1fr) [full-end];

  @media only screen and (max-width: $bp-medium) {
    margin-top: 2rem;
    grid-template-columns:
      [full-start] minmax(4rem, 1fr) [center-start] repeat(
        8,
        [col-start] minmax(min-content, 14rem) [col-end]
      )
      [center-end] minmax(4rem, 1fr) [full-end];
  }
  @media only screen and (max-width: $bp-smallest) {
    margin-top: 2rem;
    margin-bottom: 10rem;
    grid-template-columns:
      [full-start] minmax(3rem, 1fr) [center-start] repeat(
        8,
        [col-start] minmax(min-content, 14rem) [col-end]
      )
      [center-end] minmax(3rem, 1fr) [full-end];
  }
  @media only screen and (max-width: 300px) {
    margin-top: 1rem;
    grid-template-columns:
      [full-start] minmax(2rem, 1fr) [center-start] repeat(
        8,
        [col-start] minmax(min-content, 14rem) [col-end]
      )
      [center-end] minmax(2rem, 1fr) [full-end];
  }
}
.home-wrapper {
  grid-column: full-start/full-end;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $bp-smallest) {
    grid-column: full-start/full-end;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.page-wrapper {
  margin-top: 2rem;
  grid-column: full-start/full-end;
  padding-left: 20rem;
  padding-right: 20rem;
  display: flex;
  align-items: stretch;

  flex-direction: column;
  font-family: "Lato", sans-serif;
  gap: 1rem;
  @media only screen and (max-width: $bp-smallest) {
    display: flex;
   
    
    grid-column: full-start/full-end;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .user-actions{
    
    display: flex;
    align-items: center;
    gap:1rem;
    @media only screen and (max-width: $bp-smallest) {
      margin: 0 auto;
    }
   
  .btn-user-actions{
    border:none;
    background-color:var(--light-gold);
    color:white;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    &:hover{
      background-color: var(--dark-gold);
    }
    @media only screen and (max-width: $bp-smallest) {
      padding:1rem 1.5rem;
    }
   &:last-child{
    background-color:var(--color-grey-dark-3);
    &:hover{
      background-color: var(--color-grey-light-1);
    }
   } 
  }
  }

  h1,h2 {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: inherit;
    text-transform: uppercase;
    color: var(--color-grey-dark-3);
    text-align: center;
    letter-spacing: 0.8px;
  }
  .account-heading{
    text-align: center;
  }
  .account-email{
    text-align: center;
  }
  p{
    font-size: 1.2rem;
    font-family: inherit;
    font-weight: 400;
    color:var(--color-grey-dark-3);
    letter-spacing: 0.2px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
    label {
      font-size: 1.2rem;
      font-family: inherit;
      text-transform: uppercase;
      font-weight: 400;
      color:var(--color-grey-dark-3);
      @media only screen and (max-width: $bp-smallest) {
       font-size: 1rem;
      }
    }
    input {
      width: 100%;
      padding: 0.5rem 0.7rem;
      border: none;
      border-radius: 4px;
      color: var(--color-grey-dark-3);
      @media only screen and (max-width: $bp-smallest) {
        padding: 0.7rem 0.9rem;
      }
    }
    textarea {
      padding: 0.5rem 0.7rem;
      border: none;
      border-radius: 4px;
      color: var(--color-grey-dark-3);
    }
  }
  button {
    margin-top: 0.6rem;
    padding: 0.5rem 0.9rem;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-family: inherit;
    border: none;
    border-radius: 4px;
    background-color: var(--color-pink-light);
    color: white;
    @media only screen and (max-width: $bp-smallest) {
      width: 100%;
      padding: 0.7rem 0.9rem;
    }
    
  }
}
.products-list {
  margin-top: 2rem;
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1rem;

  @media only screen and (max-width: $bp-smallest) {
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    margin-top: 2rem;
    margin-bottom: 10rem;
  }
}
