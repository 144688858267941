.footer {
  grid-column: 1/-1;
  background-color: var(--color-blue-darkest);
  color: var(--color-grey-light-1);
  padding: 1rem 7em;
  margin-top: 3rem;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.1rem;
  @media only screen and (max-width: $bp-smallest) {
    padding:0 !important;
    background-color: white;
    color:var(--color-grey-dark-3);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 4rem;
  }

  .footer-container-sm{
    display: flex;
    align-items: center;
    button{
      border:none;
      padding:1rem 2rem;
      border:1px solid var(--color-grey-light-2);
      background: transparent;
      color:var(--color-grey-dark-3);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background-color: var(--color-grey-light-1); /* Active state background */
        
       /* Active border color */
      }
    
      
        svg {
          color: var(--color-grey-dark-3); /* Use color instead of fill */
          
          width: 1.7rem;
          height: 1.7rem;
        }
      
    }
  }

  .footer-container {
    display: flex;
    flex-direction: column; // Stack sections vertically
    justify-content: space-between;

    @media only screen and (min-width: $bp-medium) {
      // Responsive for larger screens
      flex-direction: row;
      justify-content: space-between;
    }

    .footer-section {
      flex: 1;
      padding: 1rem;

      h3 {
        margin-bottom: 1rem;
        font-size: 1.4rem;
        font-weight: normal;
        font-family: inherit;
      }

      p {
        margin-bottom: 1rem;
        font-size: .9rem;
        font-family: inherit;
      }

      a {
        color: inherit; // Link color
        text-decoration: none;
        font-family: inherit;
        text-transform: uppercase;
        font-size: .8rem;
        letter-spacing: 0.5px;
        &:hover {
          text-decoration: underline; // Underline on hover
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    padding: 0.5rem 0;
    font-family: inherit;
    border-top: 1px solid var(--color-grey-light-1);
    font-size: 0.8rem;
  }
}
