.login-container {
  z-index: 10000;
  height: 100vh; // Ensures the container takes up the entire viewport height
  width: 100%;
  background-color: var(--color-grey-light-2);
  display: flex; // Use flexbox to center the wrapper
  justify-content: center; // Centers horizontally
  align-items: center; // Centers vertically

  @media only screen and (max-width: $bp-smallest) {
    background-color: white;
    align-items: stretch; // Allow stretching of content on small screens
  }

  .wrapper-login {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(7, 5vw);
    border-radius: 50px;
    width: 45%; // Default width for larger screens
    margin: 5rem auto;

    @media only screen and (max-width: $bp-smallest) {
      width: 100%; // Full width on small screens
      display: flex;
      flex-direction: column;
      align-items: center; // Centering elements horizontally
      padding: 1rem; // Optional: add padding for spacing
    }

    .image-container {
      grid-column: 1 / span 3;
      grid-row: 1 / span 7;
      display: grid;
      grid-template: repeat(4, 1fr) / repeat(8, 1fr);

      @media only screen and (max-width: $bp-smallest) {
        display: none; // Hide image container on small screens
      }

      .image-figure {
        grid-column: 1 / span 8;
        grid-row: 1 / span 4;
      }

      .login-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }

    .form-container {
      grid-column: 4 / span 5;
      grid-row: 1/-1;
      background-color: white;
      padding: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      @media only screen and (max-width: $bp-smallest) {
        padding: 1rem !important; // Remove padding on small screens
        width: 100%; // Ensure form container is full width
      }

      .logo {
        margin: 2.5rem 0;
        margin-bottom: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        letter-spacing: 0.1rem;

        .site-title {
          text-decoration: none;
          color: var(--color-grey-dark-4);
        }
      }

      .login-text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--color-grey-dark-4);
        letter-spacing: 0.1rem;
      }

      .login-form {
        margin-top: 2rem;
        display: flex;
        padding: 0 1rem;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 1rem;

        @media only screen and (max-width: $bp-smallest) {
          display: block;
          padding: 0 !important;
          gap: 1rem; // Add space between the form controls
        }

        .formcontrol {
          font-family: inherit;
          font-size: 1.1rem;
          color: var(--color-grey-dark-4);
          margin-bottom: 1rem;
          border: 0.6px solid rgb(228, 220, 220);
          letter-spacing: 0.1rem;
          padding: 0.8rem 2rem;
          border-radius: 3px;
          width: 100%; // Full width of input fields on small screens
          transition: all 0.2s;

          &:focus {
            outline: none;
          }

          @media only screen and (max-width: $bp-smallest) {
            padding: 0.9rem 1rem; // Adjust padding for smaller screens
          }
        }
      }

      .forget-password {
        color: var(--color-grey-dark-4);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1.4rem;

        @media only screen and (max-width: $bp-smallest) {
          margin: 0.7rem 0;
        }
      }

      .register {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1.8rem;

        @media only screen and (max-width: $bp-smallest) {
          margin: 0.7rem 0;
        }

        .register-link,
        .login-link {
          text-decoration: none;
          color: var(--color-grey-darkest);
          text-decoration: underline;
        }
      }
    }
  }
}

.login-btn {
  margin: 0.7rem 0;
  background-color: var(--color-blue-darkest);
  border: none;
  border-radius: 6px;
  padding: 1rem 2rem;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--color-blue-dark);
  }
  @media only screen and (max-width: $bp-smallest) {
    margin: 0.3rem 0;
    width: 100%;
  }
}
.google-btn {
  margin: 0.7rem 0;
  background-color: var(--color-grey-light-3);
  border: none;
  border-radius: 6px;
  padding: 1rem 2rem;
  color: var(--color-grey-dark-4);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: background-color 0.3s ease;
  .google-icon {
    font-size: 1.3rem;
    margin-left: 1rem;
  }

  &:hover {
    transition: all 0.4s;
    background-color: white;
    border: 1px solid black;
    color: black;
  }

  .google-icon {
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  @media only screen and (max-width: $bp-smallest) {
    margin: 0.3rem 0;
    width: 100%;
  }
}






// .login-container {
//   z-index: 10000;
//   height: 100vh; // Ensures the container takes up the entire viewport height
//   width: 100%;
//   background-color: var(--color-grey-light-2);
//   display: flex; // Use flexbox to center the wrapper
//   justify-content: center; // Centers horizontally
//   align-items: center; // Centers vertically

//   @media only screen and (max-width: $bp-smallest) {
//     background-color: white;
//     align-items: stretch;
//   }

//   .wrapper-login {
//     display: grid;
//     grid-template-columns: repeat(8, 1fr);
//     grid-template-rows: repeat(7, 5vw);
//     border-radius: 50px;
//     width: 45%;
//     @media only screen and (max-width: $bp-smallest) {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       // align-items: center;
//     }
//     .image-container {
//       grid-column: 1 / span 3;
//       grid-row: 1 / span 7;
//       display: grid;
//       grid-template: repeat(4, 1fr) / repeat(8, 1fr);
//       @media only screen and (max-width: $bp-smallest) {
//         display: none;
//       }

//       .image-figure {
//         grid-column: 1 / span 8;
//         grid-row: 1 / span 4;
//       }
//       .login-img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         display: block;
//       }
//     }
//     .form-container {
//       grid-column: 4 / span 5;
//       grid-row: 1/-1;
//       background-color: white;
//       padding: 1rem;
//       display: flex;
//       align-items: center;
//       flex-direction: column;
//       @media only screen and (max-width: $bp-smallest) {
//         width: 100%;
//         padding: 0 !important;
//       }

//       .logo {
//         margin: 2.5rem 0;
//         margin-bottom: 1.4rem;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-weight: 300;
//         letter-spacing: 0.1rem;
//         .site-title {
//           text-decoration: none;
//           color: var(--color-grey-dark-4);
//         }
//       }
//       .login-text {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-size: 1.2rem;
//         font-weight: 600;
//         text-transform: uppercase;
//         color: var(--color-grey-dark-4);
//         letter-spacing: 0.1rem;
//         letter-spacing: 0.1rem;
//       }
//       .login-form {
//         margin-top: 2rem;
//         display: flex;
//         padding: 0 1rem;
//         flex-direction: column;
//         align-items: stretch;
//         margin-bottom: 1rem;
//         @media only screen and (max-width: $bp-smallest) {
//           padding: 0 !important;
//           gap: 1rem;
//         }
//         .formcontrol {
//           font-family: inherit;
//           font-size: 1.1rem;
//           color: var(--color-grey-dark-4);
//           margin-bottom: 1rem;
//           border: 0.6px solid rgb(228, 220, 220);
//           letter-spacing: 0.1rem;
//           padding: 0.8rem 2rem;
//           border-radius: 3px;
//           width: 100%;
//           transition: all 0.2s;
//           &:focus {
//             outline: none;
//           }
//           @media only screen and (max-width: $bp-smallest) {
//             padding: 0.9rem 1rem;
//           }
//         }
//       }
//       .forget-password {
//         color: var(--color-grey-dark-4);
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-size: 1.2rem;
//         font-weight: 400;
//         margin-bottom: 1.4rem;
//         @media only screen and (max-width: $bp-smallest) {
//           margin: 0.7rem 0;
//         }
//       }
//       .register {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-size: 1.2rem;
//         font-weight: 400;
//         margin-bottom: 1.8rem;
//         @media only screen and (max-width: $bp-smallest) {
//           margin: 0.7rem 0;
//         }
//         .register-link,
//         .login-link {
//           text-decoration: none;
//           color: var(--color-grey-darkest);
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }

// .login-btn {
//   margin: 0.7rem 0;
//   background-color: var(--color-blue-darkest);
//   border: none;
//   border-radius: 6px;
//   padding: 1rem 2rem;
//   color: white;
//   text-transform: uppercase;
//   letter-spacing: 0.1rem;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: var(--color-blue-dark);
//   }
//   @media only screen and (max-width: $bp-smallest) {
//     margin: 0.3rem 0;
//   }
// }
// .google-btn {
//   margin: 0.7rem 0;
//   background-color: var(--color-grey-light-3);
//   border: none;
//   border-radius: 6px;
//   padding: 1rem 2rem;
//   color: var(--color-grey-dark-4);
//   text-transform: uppercase;
//   letter-spacing: 0.1rem;
//   transition: background-color 0.3s ease;
//   .google-icon {
//     font-size: 1.3rem;
//     margin-left: 1rem;
//   }

//   &:hover {
//     transition: all 0.4s;
//     background-color: white;
//     border: 1px solid black;
//     color: black;
//   }

//   .google-icon {
//     font-size: 1.3rem;
//     margin-left: 1rem;
//   }
//   @media only screen and (max-width: $bp-smallest) {
//     margin: 0.3rem 0;
//   }
// }
