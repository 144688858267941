.tooltip {
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 120%; /* Position it above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #0e0808;
    padding: 5px 10px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    font-size: 12px;
    white-space: nowrap;
  }
  
  .tooltip:hover::after {
    opacity: 1;
    visibility: visible;
  }