@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300&family=Inter:wght@300;400;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Parisienne&display=swap');
h1{
   
    font-weight: 300;
    font-family: 'Libre Bodoni', serif;
}
h4{
    font-size: 20rem;

}
