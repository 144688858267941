.home-categories {
  //background-color: white;
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(9, 1fr);
  margin: 2rem 0;
  font-family: "Lato", sans-serif;
  @media only screen and (max-width: $bp-medium) {
    grid-template: repeat(6, 1fr) / repeat(9, 1fr);
    grid-gap: 1rem;
  }
  @media only screen and (max-width: $bp-smallest) {
    grid-template: repeat(3, minmax(130px, auto)) / repeat(2, 1fr);
    grid-gap: 1rem;
  }

  .item-1 {
    grid-column: 1 / span 3;
    grid-row: 1 / span 3;
    //background-color:  #f9c8ae;

    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
    }
  }
  .item-2 {
    grid-column: 4 / span 3;
    grid-row: 1 / span 3;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;

    //background-color: #cefdeb;
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 1 / span 1;
      grid-column: 2 / span 1;
      margin-right: 0 !important;
    }
  }
  .item-3 {
    grid-column: 7 / span 3;
    grid-row: 1 / span 3;
    margin-bottom: 1rem !important;
    //background-color: #eae2e2;
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
      margin-right: 1rem !important;
    }
  }
  .item-4 {
    grid-column: 1 / span 3;
    grid-row: 4 / span 3;
    margin-right: 1rem !important;

    //background-color: #fafbb6;

    @media only screen and (max-width: $bp-smallest) {
      grid-row: 2 / span 1;
      grid-column: 2 / span 1;
      margin: 0 !important;
      margin-bottom: 1rem !important;
    }
  }
  .item-5 {
    grid-column: 4 / span 3;
    grid-row: 4 / span 3;
    margin-right: 1rem !important;

    //background-color: #d7edf5;
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 3 / span 1;
      grid-column: 1 / span 1;
    }
  }
  .item-6 {
    grid-column: 7 / span 3;
    grid-row: 4 / span 3;

    //background-color: #f9c8ae;
    //background-color:#eebec8;
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 3 / span 1;
      grid-column: 2 / span 1;
    }
  }
  .item-1,
  .item-2,
  .item-3,
  .item-4,
  .item-5,
  .item-6 {
    border-radius: 4px;
    display: grid;
    grid-template-rows: repeat(3, 5vw);
    grid-template-columns: repeat(8, 1fr);

    //border-radius: 4px;
    .text-main-1,
    .text-main-2,
    .text-main-3,
    .text-main-4,
    .text-main-5,
    .text-main-6 {
      grid-column: 1 / span 3;
      grid-row: 1 / span 3;
      display: flex;
      align-items: center;
      flex-direction: column;
      //background-color: yellow;

      justify-content: center;
      //background-color:  #f9c8ae;
      //background-color: red;
      background-color: white;
      @media only screen and (max-width: $bp-medium) {
        grid-column: 2 / span 2;
        grid-row: 2 / span 2;
       

        //margin-left: 3rem !important;
      }
      @media only screen and (max-width: $bp-smallest) {
        grid-column: 1 / span 8;
        grid-row: 2 / span 1;
        flex-direction: row;
      }
      .banner-text-1,
      .banner-text-2,
      .banner-text-3,
      .banner-text-4,
      .banner-text-5,
      .banner-text-6 {
        font-family: inherit;

        letter-spacing: 0.4rem;
        color: var(--color-grey-dark-3);
        font-size: 1.4rem;
        font-weight: 400;
        @media only screen and (max-width: $bp-medium) {
          font-size: 1.4rem;
          font-weight: 500;
          letter-spacing: 0.6rem;
        }
        @media only screen and (max-width: $bp-smallest) {
          font-size: 1.2rem;

          font-weight: 500;
          letter-spacing: 0.2rem;
        }
        @media only screen and (max-width: 300px) {
          font-weight: 400;
          font-size: 1rem;
        }
      }
      .banner-btn-1,
      .banner-btn-2,
      .banner-btn-3,
      .banner-btn-4,
      .banner-btn-5,
      .banner-btn-6 {
        color: var(--color-grey-dark-1);
        background-color: var(--color-grey-light-3);
        font-weight: 500;
        letter-spacing: 0.4rem;
        margin-top: 0.7rem !important;
        padding: 0.8rem 2rem;
        border: none;
        @media only screen and (max-width: $bp-medium) {
          margin-top: 0.5rem;
          font-size: 1.1rem;
          padding: 0.3rem 2.1rem;
        }
        @media only screen and (max-width: $bp-smallest) {
          //margin-top: 1rem;
          margin-left: 0.4rem !important;
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 0.4rem;
          padding: 0.3rem 0.5rem;
          border: none;
          font-size: 1rem;
          font-weight: 600;
          margin-top: 0 !important;
        }
        @media only screen and (max-width: 300px) {
          padding: 0.4rem 0.4rem;
          font-size: 0.8rem;
        }
      }
    }

    .image-container-1,
    .image-container-2,
    .image-container-3,
    .image-container-4,
    .image-container-5,
    .image-container-6 {
      grid-column: 4 / span 5;
      grid-row: 1/-1;
      height: 100%;
      z-index: 300;

      // @media only screen and (max-width: $bp-smallest) {
      //   grid-column: 5 / span 4;
      //   grid-row: 1 / span 3;
      // }
      @media only screen and (max-width: $bp-smallest) {
        grid-column: 1 / span 8; /* Adjust grid column for small screens */
        grid-row: 1 / span 1; /* Adjust grid row for small screens */
        height: auto;
        width: 100%; /* Make height auto to fit content properly */
      }
      .image-1,
      .image-2,
      .image-3,
      .image-4,
      .image-5,
      .image-6 {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
    @media only screen and (max-width: $bp-smallest) {
      background-color: white;
      grid-template: repeat(2, minmax(13vw, auto)) / repeat(8, 1fr);
    }
  }
}
