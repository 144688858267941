.products {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  // gap: 2rem;
  // @media only screen and (max-width: $bp-smallest) {
  //   grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  //   gap:1rem;
  //   margin:0 auto;
  // }
  display: flex; // Use flexbox for layout
  flex-wrap: wrap; // Allow wrapping
  justify-content: flex-start; // Align items to the left
  gap: 2rem; // Space between items

  // Responsive adjustments
  @media only screen and (max-width: $bp-smallest) {
    grid-column: full-start / full-end;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 1rem;
    margin: 2rem auto;
  }

  // Align items based on count
  & > div {
    flex: 0 1 calc(25% - 2rem); // Adjust width; 4 items per row
    min-width: 200px; // Ensure a minimum width
  }

  @media only screen and (max-width: 768px) {
    & > div {
      flex: 0 1 calc(50% - 2rem); // 2 items per row on medium screens
    }
  }

  @media only screen and (max-width: 480px) {
    & > div {
      flex: 0 1 100%; // 1 item per row on small screens
    }
  }
 
}
