// @mixin animation($content) {
//   animation: $content;
//   -moz-animation: $content;
//   -webkit-animation: $content;
// }

// @mixin keyframes($name) {
//   @keyframes #{$name} { @content; }
//   @-moz-keyframes #{$name} { @content; }
//   @-webkit-keyframes #{$name} { @content; }
// }

// .radio-group {
//   width: 50%;
//   display: inline-block;
// }

// .radio-input {
//   display: none;
// }
// .radio-label {
//   font-size: 1.3rem;
//   cursor: pointer;
//   padding-left: 2rem;
//   position: relative;
// }
// .radio-button {
//   height: 3rem;
//   width: 3rem;
//   border: 4px solid rgb(25, 1, 48);
//   border-radius: 50%;
//   display: inline-block;
// }
// .radio-button::after {
//   content: "";
//   display: block;
//   height: 1.3rem;
//   width: 1.3rem;
//   border-radius: 50%;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: rgb(25, 1, 48);
//   opacity: 0;
//   transition: opacity 0.2s;
// }
// .radio-input:checked ~ .radio-label .radio-button::after {
//   opacity: 1;
// }
// .checkbox-label {
//   color: rgb(204, 39, 39);
//   display: block;
//   position: relative;
//   margin: auto;
//   cursor: pointer;
//   font-size: 22px;
//   line-height: 24px;
//   height: 50px;
//   width: 24px;
//   clear: both;
// }
// .checkbox-label input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
// }

// .checkbox-label .mark {
//   top: 30px;
//   position: absolute;
//   height: 24px;
//   width: 24px;
//   background-color: transparent;
//   border-radius: 5px;
//   transition: all 0.3s ease-in;
//   border: 2px solid rgb(14, 1, 1);
//   padding: 2px 5x;
// }
// .checkbox-label input:checked ~ .mark {
//   background-color: rgb(243, 240, 240);
//   border-radius: 5px;
//   transform: rotate(0deg) scale(1);
//   opacity: 1;
//   border: 2px solid rgb(17, 1, 1);
// }
// .checkbox-label .mark::after {
//   position: absolute;
//   content: "";
//   border-radius: 5px;
// }
// .checkbox-label input:checked ~ .mark::after {
//   transform: rotate(45deg) scale(1);
//   left: 8px;
//   top: 3px;
//   width: 6px;
//   height: 12px;
//   border: solid rgb(57, 9, 170);
//   border-width: 0 2px 2px 0;
//   border-radius: 0;
// }
// /* For Ripple Effect */
// .checkbox-label .mark::before {
//   position: absolute;
//   content: "";
//   border-radius: 10px;
//   border: 5px solid yellow;
//   transform: scale(0);
// }

// .checkbox-label input:checked ~ .mark::before {
//   left: -3px;
//   top: -3px;
//   width: 24px;
//   height: 24px;
//   border-radius: 5px;
//   transform: scale(3);
//   opacity: 0;
//   transition: all 0.3s ease-out;
// }


  .products-wrapper {
    grid-column: center-start/center-end;

    display: grid;
    grid-template-columns: 0.2fr 1fr;
    @media only screen and (max-width: $bp-smallest) {
      grid-column: full-start/full-end;
      grid-template-columns: 1fr;
    }
    .left {
      margin-top: 2rem;
    
      display: flex;
      flex-direction: column;
      gap:2rem;
      @media only screen and (max-width: $bp-smallest) {
       display: none;
      }
      //justify-content: space-between;
     
      
      .filter-1,
      .filter-2,
      .filter-3,
      .filter-4 {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .filter-name {
          font-size: 1.2rem;
          font-weight: 400;
          font-family: "lato", sans-serif;
        }
        .buttons-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-right: 3rem;
          button {
            width: 1.7rem;
            height: 1.7rem;
            border: none;
            border-radius: 50%;
          }
        }
        .review-filter {
          list-style-type: none;
          
          li {
            margin-left: -2rem !important;
          }
        }
        .review-filter > li:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }

      .category-checkbox {
        display: flex;
        margin-bottom: 0.3rem;
      }
    }
    .right{
      margin-top: 2rem;
    display: flex;
    flex-direction: column;
   
      .sort{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .filter-clear-wrapper{
          display: flex;
          align-items: center;
         justify-content: space-between;
         gap:1rem;
          .filter-clear-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap:.3rem;
            span{
              font-family: 'lato',sans-serif;
              font-size: 1.2rem;
              font-weight: 400;
            }
            .clear-btn{
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: none;
              border-radius: 3px;
              background-color: var(--color-grey-light-1);
              padding:8px 12px;
             
             
              span{
                font-family: 'lato',sans-serif;
              font-size: 1.2rem;
              font-weight: 300;
              text-transform: capitalize;
              }
              .icon-filter{
                font-size: 1.2rem;
               margin-left: .7rem !important;

              }
            }
           
          }
          
        }
      
     
      .dropdown{
        width: 200px;
        position: relative;
        font-family: 'lato',sans-serif;
        user-select: none;
        z-index: 400;
      }
      .dropdown .dropdown-btn{
        padding:8px 15px;
        background: #fff;
        box-shadow: 3px 3px 4px 4px rgba(0,0,0,0.06);
        font-weight: 500;
        font-size: 1.3rem;
        color:var(--color-grey-dark-3);
        display:flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        
      }
      .dropdown .dropdown-content{
        position: absolute;
        top:110%;
        padding:8px 10px;
        background: #fff;
        box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
        font-weight: 400;
        font-size: 1.2rem;
        color:var(--color-grey-dark-2);
        width:100%;
      }
      .dropdown .dropdown-content .dropdown-item{
        padding:8px 10px;
        cursor: pointer;
        transition: all .2s;
        &:hover{
          background-color: #f4f4f4;

        }
      }
    }
      
    .products-wrapper{
      //background-color: yellow;
     
      margin-top: 3rem;
      padding:2rem;
     
      @media only screen and (max-width: $bp-smallest) {
        
       padding:1rem;
      }
      
    }
    }
  
  }


