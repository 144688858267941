.order-success-container{
    margin-top: 2rem;
    grid-column: center-start/center-end;
    background-color: white;
    padding:2rem 3rem;
    display: flex;
    flex-direction: column;
    gap:1rem;
    font-family:'Lato',sans-serif;
    h3{
        font-size: 1.3rem;
        
        text-transform: uppercase;
        font-family: inherit;
        color:var(--color-grey-dark-3);
        letter-spacing: 1px;
        

      }
      .order-summary{
        p{
          font-size: 1.2rem;
          font-family: inherit;
          color:var(--color-grey-dark-3);
        }
      }
      .order-actions{
        button{
          background: none;
          border:none;
          background-color: var(--color-pink-dark);
          color:white;
          padding:0.7rem 2rem;
          border-radius: 4px;
          &:hover{
            background-color: var(--light-gold);
          }
        }
      }
}