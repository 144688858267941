$accordion-bg-color: #f4f4f4;
$accordion-hover-bg: #e0e0e0;
$accordion-border-color: #ccc;
$accordion-border-radius: 4px;
$accordion-padding: 10px;
$accordion-header-padding: 15px;

.orders-wrapper {
  margin-top: 2rem;
  grid-column: full-start/full-end;
      padding-left: 4rem;
      padding-right: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: "Lato", sans-serif;

  @media only screen and (max-width: $bp-smallest) {
   
    padding-left: 1rem;
      padding-right: 1rem;
  }

  h3 {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-family: inherit;
    color: var(--color-grey-dark-3);
    letter-spacing: 1px;
    text-align: center;
  }

  .order {
    background-color: white;
    border-radius: $accordion-border-radius;
    border: none;
    overflow: hidden;
    transition: background-color 0.3s ease;
    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }

    &:hover {
      background-color: var(--color-grey-light-1);
    }

    .order-header {
      display: grid;
      grid-template-columns: repeat(5,1fr);
      padding:1rem 2rem;
      gap:1rem;
      cursor: pointer;
      @media only screen and (max-width: $bp-smallest) {
        grid-template-columns: repeat(3,1fr);
    }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.4rem;
        @media only screen and (max-width: $bp-smallest) {
            align-items: start;
        }
       

        h4,
        h6 {
          margin: 0;
       
          font-size: 1rem;
          color: var(--color-grey-dark-2);
          font-family: inherit;
        }

        .status {
          padding: 0.3rem 0.6rem;
          border-radius: 5px;
          color: var(--color-grey-dark-2);
          font-size: 0.8rem;
        }

        .orderno {
          font-weight: bold;
          color: var(--color-grey-dark-1);
        }

       
       
      }
      p {
        
        font-size: 1rem;
        font-weight: bold;
        font-family: inherit;
        align-self: center;
        
        @media only screen and (max-width: $bp-smallest) {
         display: none;
      }
      }
      .updownicon{
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          @media only screen and (max-width: $bp-smallest) {
              display: none;
           }
          .icon{
              fill: var(--color-grey-dark-3);
              height: 1.5rem;
              width: 1.5rem;
          }
      }
      div:not(:first-child){
        @media only screen and (max-width: $bp-smallest) {
            align-items: center;
        }
    }
    }

    .order-details {
      padding: $accordion-padding;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      transition: all 0.3s ease;
      height: 0;
      opacity: 0;
      overflow: hidden;

      .shipping-info,
      .order-items,
      .order-summary,
      .payment-method {
        padding: 1rem;
        border: 1px solid $accordion-border-color;
        border-radius: $accordion-border-radius;
        background-color: #ffffff;

        h3 {
          font-size: 1.1rem;
          color: var(--color-grey-dark-2);
          font-family: inherit;
        }

        p {
          font-size: 1.2rem;
          font-family: inherit;
        }

        .order-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;

          // .item-details {
          //   p {
          //     margin: 0.5rem 0;
          //     color: var(--color-grey-dark-1);
          //   }
          // }
        }
      }
    }
    
  }
}
.item-details{
  display: grid;
  grid-template-columns: repeat(4,1fr);
}
.return-btn,.cancel-btn{
  border:none;
  padding:0.5rem 1rem;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-family: inherit;
  font-weight: 400;
}
.return-btn{
  background-color: var(--light-gold);
  color:white;
}

// Mobile responsiveness
@media (max-width: 768px) {
 

  .order-details {
    padding: 0.5rem;
  }

  .order-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-summary {
    padding: 0.5rem;
  }
}
