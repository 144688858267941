.categories-wrapper {
  grid-column: center-start / center-end;
  background-color: white;
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(8, 1fr);
  //grid-gap: 1rem !important;
  margin: 2rem 0;
  font-family: "Lato", sans-serif;
  @media only screen and (max-width: $bp-medium) {
    grid-template: repeat(2, minmax(132px, auto)) / repeat(6, 1fr);
    grid-gap: 1rem;
  }
  @media only screen and (max-width: $bp-smallest) {
    grid-template: repeat(3, minmax(156px, auto)) / repeat(2, 1fr);
    grid-gap: 1rem;
  }
  .item-1 {
    grid-column: 1 / span 4;
    grid-row: 1 / span 6;
    background-color: #eae2e2;
    display: grid;
    grid-template: repeat(6, minmax(4vw, auto)) / repeat(8, 1fr);
    margin-right: 1rem !important;
    @media only screen and (max-width: $bp-medium) {
      grid-row: 1 / span 1;
      grid-column: 1 / span 2;
      grid-template: repeat(3, minmax(5vw, auto)) / repeat(8, 1fr);
      margin: 0 !important;
    }
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 1 / span 1;
      grid-column: 1 / span 2;
      grid-template: repeat(3, minmax(13vw, auto)) / repeat(8, 1fr);
      justify-content: stretch;

      margin: 0 !important;
      margin-left: -0.3rem !important;
      margin-right: -0.3rem !important;
    }
    .text-main-1 {
      grid-column: 2 / span 2;
      grid-row: 3 / span 2;
      //background-color:yellow;
      display: flex;
      align-items: center;
      flex-direction: column;

      justify-items: center;
      @media only screen and (max-width: $bp-medium) {
        grid-column: 2 / span 2;
        grid-row: 2 / span 2;
        display: flex;
        align-items: center;
        flex-direction: column;

        justify-items: center;

        //margin-left: 3rem !important;
      }
      @media only screen and (max-width: $bp-smallest) {
        grid-column: 1 / span 3;
        grid-row: 2 / span 2;
        align-self: stretch;

        display: flex;
        align-items: center;
        flex-direction: column;

        justify-items: center;

        margin-left: 2rem !important;
      }
      .banner-text-1 {
        font-family: inherit;
        letter-spacing: 0.7rem;
        color: var(--color-grey-dark-3);
        font-size: 2rem;
        font-weight: 400;
        text-transform: uppercase;

        @media only screen and (max-width: $bp-medium) {
          font-size: 1.4rem;
          font-weight: 500;
          letter-spacing: 0.6rem;
        }
        @media only screen and (max-width: $bp-smallest) {
          font-size: 1.5rem;

          font-weight: 500;
          letter-spacing: 0.4rem;
        }
        @media only screen and (max-width: 300px) {
          font-weight: 400;
        }
      }
      .banner-btn-1 {
        color: var(--color-grey-dark-3);
        background-color: white;
        font-weight: 500;
        letter-spacing: 0.4rem;
        margin-top: 0.7rem !important;
        padding: 0.8rem 2.8rem;
        border: none;
        text-transform: uppercase;

        @media only screen and (max-width: $bp-medium) {
          margin-top: 0.5rem;
          font-size: 1.1rem;
          padding: 0.3rem 2.1rem;
        }
        @media only screen and (max-width: $bp-smallest) {
          margin-top: 1rem;
          font-size: 2rem;

          font-size: 1rem;
          font-weight: 600;
          color: var(--color-grey-dark-3);
          background-color: white;
        }
        @media only screen and (max-width: 300px) {
          padding: 0.4rem 1rem;
        }
      }
    }
    .image-container-1{
        grid-column:4/span 4;
        grid-row:1/span 6;
         @media only screen and (max-width: $bp-medium) {
           grid-column: 5/span 4;
           grid-row: 1/span 4;
         }
 
         @media only screen and (max-width: $bp-smallest) {
           grid-column: 5 / span 4;
           grid-row: 1 / span 3;
           display: flex;
           align-items: stretch;
         }
         .image-1{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
         }
         

    }
  }
  .item-2 {
    background-color: #f7caca;
    grid-column: 5 / span 2;
    grid-row: 1 / span 3;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
    @media only screen and (max-width: $bp-medium) {
      grid-row: 1 / span 1;
      grid-column: 3 / span 2;
      margin: 0 !important;
    }
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
      margin: 0 !important;
    }
  }
  .item-3 {
    background-color: #eae2e2;
    grid-column: 7 / span 2;
    grid-row: 1 / span 3;
    margin-bottom: 1rem !important;
    @media only screen and (max-width: $bp-medium) {
      grid-row: 1 / span 1;
      grid-column: 5 / span 2;
      margin: 0 !important;
    }
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 2 / span 1;
      grid-column: 2 / span 1;
      margin: 0 !important;
    }
  }
  .item-4 {
    background-color: #d7edf5;
    grid-column: 5 / span 2;
    grid-row: 4 / span 3;
    margin-right: 1rem !important;
    @media only screen and (max-width: $bp-medium) {
      grid-row: 2 / span 1;
      grid-column: 2 / span 2;
      margin: 0 !important;
    }
    @media only screen and (max-width: $bp-smallest) {
      grid-row: 3 / span 1;
      grid-column: 1 / span 1;
      margin: 0 !important;
    }
  }
  .item-5 {
    background-color: #fffee0;
    grid-column: 7 / span 2;
    grid-row: 4 / span 3;
    @media only screen and (max-width: $bp-medium) {
      grid-row: 2 / span 1;
      grid-column: 4 / span 2;
      margin: 0 !important;
    }

    @media only screen and (max-width: $bp-smallest) {
      grid-row: 3 / span 1;
      grid-column: 2 / span 1;
    }
  }
  .item-2,
  .item-3,
  .item-4,
  .item-5 {
    display: grid;
    grid-template: repeat(3, 4vw) / repeat(8, 1fr);
    .text-main-2,.text-main-3,.text-main-4,.text-main-5{
        grid-column: 2/span 1;
        grid-row: 2/span 2;
        display: flex;
        align-items: center;
        flex-direction: column;
       
        justify-items: center;
        @media only screen and (max-width: $bp-medium) {
          grid-column: 2/span 2;
          grid-row:2/span 2 ;
          display: flex;
          align-items: center;
          flex-direction: column;
         
          justify-items: center;

          //margin-left: 3rem !important;
      }

        @media only screen and (max-width: $bp-smallest) {
          grid-column: 1 / span 3;
          grid-row: 2 / span 2;

          display: flex;
          align-items: center;
          flex-direction: column;
          justify-items: center;

          margin-left: 2rem !important;
        }
        .banner-text-2,.banner-text-3,.banner-text-4,.banner-text-5{
            font-family: inherit;
        
        letter-spacing: 0.4rem;
        color: var(--color-grey-dark-3);
        font-size: 1.6rem;
        font-weight: 400;
        @media only screen and (max-width: $bp-medium) {
          font-size: 1.4rem;
          font-weight: 500;
          letter-spacing: .6rem;
        }
        @media only screen and (max-width: $bp-smallest) {
          font-size: 1.5rem;

          font-weight: 500;
          letter-spacing: 0.4rem;
        }
        @media only screen and (max-width: 300px) {
          font-weight: 400;
          font-size: 1rem;
        }
        }
        .banner-btn-2,.banner-btn-3,.banner-btn-4,.banner-btn-5{
            color: var(--color-grey-dark-3);
            background-color: white;
            font-weight: 500;
            letter-spacing: 0.4rem;
            margin-top: .7rem !important;
            padding: 0.8rem 2rem;
            border: none;
            @media only screen and (max-width: $bp-medium) {
              margin-top: .5rem;
              font-size: 1.1rem;
              padding: 0.3rem 2.1rem;
             }
              @media only screen and (max-width: $bp-smallest) {
                margin-top: 1rem;
                font-size: 2rem;
                font-weight: 500;
                letter-spacing: 0.4rem;
                padding: 0.8rem 1rem;
                border: none;
                font-size: 1rem;
                font-weight: 600;
                color: var(--color-grey-dark-3);
                background-color: white;
              }
              @media only screen and (max-width: 300px) {
                padding: 0.4rem .8rem;
                font-size: .8rem;
              }
        }
    }
    .image-container-2,.image-container-3,.image-container-4,.image-container-5{
        grid-column:3/span 5;
      grid-row:1/span 4;

      @media only screen and (max-width: $bp-medium) {
        grid-column: 5/span 4;
        grid-row: 1/span 4;
      }
      @media only screen and (max-width: $bp-smallest) {
        grid-column: 5 / span 4;
        grid-row: 1 / span 3;
      }
      .image-2,.image-3,.image-4,.image-5{
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
    @media only screen and (max-width: $bp-medium) {
      grid-template: repeat(3, minmax(5vw, auto)) / repeat(8, 1fr);
    }
    @media only screen and (max-width: $bp-smallest) {
      grid-template: repeat(3, minmax(13vw, auto)) / repeat(8, 1fr);
    }
  }
}
