.heading{
    margin:1.5rem 0;
    grid-column: center-start/center-end;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: $bp-smallest) {
      margin: .5rem;
    }
    h2{
      color:var(--color-grey-dark-4);
      font-family: "Lato", sans-serif;
      
      font-weight: 300;
      font-size: 1.8rem;
      letter-spacing: .1rem;
      padding:1rem 2rem;
      border-bottom: .2px solid rgb(87, 84, 84);
      @media only screen and (max-width: $bp-smallest) {
        padding-bottom:1rem;
      }
    }
  }